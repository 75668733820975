<template>
    <section>
        <slot
            :modules="modules"
            :slide-change="slideChange"
            :on-swiper="onSwiper"
            :slide-index="slideIndex"
            :group-count="groupCount"
            :group-active="groupActive"
            :group-light="groupLight"
            :group-image="groupImage"
            :group-color="groupColor"
            :on-prev="onPrev"
            :on-next="onNext"
            :is-beginning="isBeginning"
            :is-end="isEnd"
        />
    </section>
</template>

<script>
    import { Lazy, Mousewheel, Parallax } from 'swiper';

    const keys = {
        37: 1, // left
        38: 1, // up
        39: 1, // right
        40: 1, // down
    };

    export default {
        data() {
            return {
                swiper: null,
                locked: false,
                timeout: null,
                lastScrollTop: window.pageYOffset || document.documentElement.scrollTop,
                modules: [ Lazy, Mousewheel, Parallax ],

                slideIndex: '001',
                groupCount: '001',
                groupActive: false,
                groupLight: false,
                groupImage: 'left',
                groupColor: null,
                isBeginning: true,
                isEnd: true,
                groups: {},
                groupId: null,
                fadeTimeout: null,
            };
        },

        mounted() {
            history.scrollRestoration = 'manual';
            this.fixHeight();

            let supportsPassive = false;
            try {
                window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
                    // eslint-disable-next-line getter-return
                    get() { supportsPassive = true; },
                }));
                // eslint-disable-next-line no-empty
            } catch (e) {}

            const wheelOpt = supportsPassive ? { passive: false } : false;
            const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

            window.addEventListener('scroll', this.onScroll);
            window.addEventListener('resize', this.fixHeight);
            window.addEventListener(wheelEvent, this.preventDefault, wheelOpt);
            window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        },

        methods: {
            fixHeight() {
                this.$el.style.height = '';
                setTimeout(() => {
                    this.$el.style.height = `${this.$el.offsetHeight}px`;
                });
            },
            preventDefault(event) {
                if (this.swiper.isEnd) {
                    return true;
                }

                event.preventDefault();
            },
            preventDefaultForScrollKeys(event) {
                if (this.swiper.isEnd) {
                    return true;
                }

                if (keys[event.keyCode]) {
                    event.preventDefault(event);
                    return false;
                }
            },
            onScroll() {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                if (scrollTop <= 0) {
                    this.swiper.mousewheel.enable();

                    if (this.swiper.activeIndex >= (this.swiper.slides.length - 1) && !this.swiper.animating) {
                        this.swiper.slidePrev();
                    }

                    return false;
                }

                this.swiper.mousewheel.disable();

            },
            onSwiper(swiper) {
                this.swiper = swiper;
                swiper.params.mousewheel.releaseOnEdges = swiper.activeIndex >= (swiper.slides.length - 1);

                let index = 0;
                for (const slide of swiper.slides) {
                    if (typeof slide.dataset.blockId !== 'undefined') {
                        if (typeof this.groups[slide.dataset.blockId] === 'undefined') {
                            this.groups[slide.dataset.blockId] = {
                                startIndex: index,
                                imageSide: slide.dataset.blockImage,
                                tint: slide.dataset.blockTint,
                                color: slide.dataset.blockColor,
                            };
                        } else {
                            this.groups[slide.dataset.blockId].endIndex = index;
                        }
                    }

                    index++;
                }
            },
            slideChange(swiper) {
                clearTimeout(this.timeout);

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                this.isBeginning = swiper.isBeginning;
                this.isEnd = swiper.isEnd;

                let groupActive = false;
                let groupChanged = false;
                let groupImage = null;

                clearTimeout(this.fadeTimeout);

                for (const groupId in this.groups) {
                    if (Object.prototype.hasOwnProperty.call(this.groups, groupId)) {
                        const group = this.groups[groupId];

                        if (swiper.activeIndex >= group.startIndex && swiper.activeIndex <= group.endIndex) {
                            groupChanged = this.groupId !== groupId;
                            this.groupId = groupId;

                            this.isBeginning = swiper.activeIndex === group.startIndex;
                            this.isEnd = swiper.activeIndex === group.endIndex;
                            this.slideIndex = `${(swiper.activeIndex - group.startIndex + 1)}`.padStart(3, '0');
                            this.groupCount = `${(group.endIndex - group.startIndex + 1)}`.padStart(3, '0');
                            this.groupLight = group.tint === 'light';
                            this.groupColor = group.color;

                            groupImage = group.imageSide;
                            groupActive = true;
                            break;
                        }
                    }
                }

                if (groupActive) {
                    if (groupChanged) {
                        this.groupActive = false;
                    }

                    this.fadeTimeout = setTimeout(() => {
                        this.groupActive = true;
                        this.groupImage = groupImage;
                    }, 800);
                } else {
                    this.groupActive = false;
                }

                this.timeout = setTimeout(() => {
                    swiper.params.mousewheel.releaseOnEdges = swiper.isEnd;
                }, swiper.params.speed + 200);
            },
            onPrev() {
                this.swiper.slidePrev();
            },
            onNext() {
                this.swiper.slideNext();
            },
        },
    };
</script>
