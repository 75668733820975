<template>
    <header>
        <slot :is-menu-open="isMenuOpen" :on-toggle-menu="onToggleMenu" />
    </header>
</template>

<script>
    export default {
        data() {
            return {
                isMenuOpen: false,
            };
        },

        methods: {
            onToggleMenu() {
                this.isMenuOpen = !this.isMenuOpen;
            },
        },
    };
</script>
