// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Animate on scroll
import AOS from 'aos';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, defineAsyncComponent } from 'vue';
import apolloProvider from './apollo';

// Components
import Rot13Email from './components/Rot13Email.vue';
import MainMenu from './components/MainMenu.vue';
import PageBlocks from './components/PageBlocks.vue';
const Projects = defineAsyncComponent(() => import('./components/Projects.vue'));

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Autoplay,
    Lazy,
} from 'swiper';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

const app = createApp({
    delimiters: [ '${', '}' ],

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            swiperModules: [ Autoplay, Lazy ],
        };
    },

    mounted() {
        setTimeout(() => {
            AOS.init({
                duration: 600,
            });
        }, 300);
    },

    methods: {
        onReturnToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        transitionStart(swiper) {
            const activeSlide = swiper.slides[swiper.activeIndex];
            for (const elem of activeSlide.querySelectorAll('[data-aos]')) {
                elem.style.display = 'none';
                elem.classList.remove('aos-animate');
            }
        },
        transitionEnd(swiper) {
            const activeSlide = swiper.slides[swiper.activeIndex];
            for (const elem of activeSlide.querySelectorAll('[data-aos]')) {
                elem.style.display = '';

                setTimeout(() => {
                    elem.classList.add('aos-animate');
                }, 0);
            }
        },
    },
});

app.component('Rot13Email', Rot13Email);
app.component('MainMenu', MainMenu);
app.component('PageBlocks', PageBlocks);
app.component('Projects', Projects);
app.component('CollapseTransition', CollapseTransition);

app.use(apolloProvider);

app.mount('#app');
